































































import { ITip, ITipResponse } from '@/interfaces/tip';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    data() {
        return {
            apiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
            active: false,
            tip: {} as ITip,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<ITipResponse>('tips', {
                text: this.tip.text,
            }).then(({ data }) => {
                this.$router.push({ name: 'tips.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
